<template>
  <div class="registerSuccess">
    <div class="container">
      <section id="contentbox" class="h-100 mb-5">
        <div class="bg-dark text-white border border-secondary rounded px-3 py-2 mt-2">
          <div class="row">
            <div class="col-12 col-sm-12 col-md-6">
              <div class="indexlogo">
                <!-- <div class="logoindex text-center">
                  <a href="#"
                    ><img src="@/assets/wisdom/logo wisdombet-03.png" alt="WISDOMBET" title="WISDOMBET"
                  /></a>
                </div> -->
              </div>
            </div>
            <div class="col-12 col-sm-12 col-md-6 text-center text-sm-center text-md-right">
              <h3 class="mt-1 font-weight-light">สมัครสมาชิก</h3>
            </div>
          </div>
        </div>
        <div class="bg-white border-secondary shadow-sm rounded p-3 h-100 mt-2 mb-5">
          <h5 class="text-success">
            <span class="badge badge-pill badge-success font-weight-light">STEP 3</span> สร้างบัญชีสำเร็จ
          </h5>
          <div class="text-center my-4">
            <div class="col-12 col-sm-12 text-center pb-1 pt-2">
              <img :src="require('@/assets/menu_top/regis5.png')" class="-cover-img img-fluid ls-is-cached lazyloaded" alt="wt-pretty-gaming" width="50%"/>
            </div>
            <!-- <span style="font-size: 100px; line-height: 1.2"><i class="far fa-check-circle text-success"></i></span> -->
            <h4 class="text-success" style="font-family: inherit">สมัครสมาชิกเรียบร้อย</h4>
          </div>
          <div class="bg-light border border-primary text-center pt-2 mb-2">
            <h5 class="text-primary">ข้อมูลสมาชิกของท่าน</h5>
            <hr />
            <center>
              <table>
                <tbody>
                  <tr>
                    <td><span class="badge badge-primary w-100">ชื่อผู้ใช้</span></td>
                    <td class="pl-2">{{ username }}</td>
                  </tr>
                  <tr>
                    <td><span class="badge badge-primary w-100">รหัสผ่าน</span></td>
                    <td class="pl-2">***********</td>
                  </tr>
                </tbody>
              </table>
            </center>
            <br />
            กรุณาจด ข้อมูลไว้เพื่อใช้เข้าเล่นครั้งต่อไป<br />
            <div
              class="bg-primary p-1 mt-2 text-light d-flex flex-column flex-sm-column flex-md-row justify-content-center"
            >
              <span class="mx-1 w-100">กรุณาเก็บข้อมูลของท่าน เพื่อใช้ในการเข้าเล่นครั้งต่อไป</span>
            </div>
          </div>

          <div class="alert alert-secondary text-center">
            ขั้นตอนต่อไป <b>เพิ่มบัญชีธนาคาร</b> เพื่อทำการ <b>ลงทะเบียน</b> ให้เสร็จสมบูรณ์
          </div>
        </div>
      </section>
    </div>
    <div class="bg-secondary border-dark shadow-sm p-3 mt-2 mb-0">
      <div class="row">
        <div class="col">
          <router-link to="/member/settings/bank/add" tag="button" class="btn btn-success btn-block btn-lg">
              <i class="fas fa-plus"></i> เพิ่มบัญชีธนาคาร
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      language: localStorage.getItem('locale') ?? 'th',
      username: localStorage.getItem('username'),
      password: localStorage.getItem('password')
    }
  }
}
</script>

<style scoped>
</style>
